$('.j-success-stories-slider').slick({
    slidesToShow: 1,
    arrows: true,
    dots: true,
    nextArrow: '.j-ss-next',
    prevArrow: '.j-ss-prev',
    appendDots: '.j-ss-dots'
});


$(".scrollTo").click(function(e) {
    e.preventDefault();

    var elemID = $(this).attr('data-id');

    console.log(elemID);

    $('html, body').animate({
        scrollTop: $(elemID).offset().top
    }, 1000);
});


// form labels
$('.fly-labels .field .input').focus(function() {
    $(this).parent().addClass('focus');
});

$('.fly-labels .field .input').blur(function() {
    if (!$(this).val()) {
        $(this).parent().removeClass('focus');
    }
});


 $(function() {
  $('.mchimp form').ajaxChimp({
    callback: function(response) {
      $('.mchimp form .result').text(response.msg);
    }
  });
})




$(document).ready(function() {

    var width = $(window).width();


    if (width <= 768) {
       
    } else {
        $(document).on('click', '.our-services--box', function() {
            $(this).toggleClass('open');
        });
    }


});


$('.j-carousell-img-1').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: false,
    dots: false,
    asNavFor: '.j-carousell-nav-1'
});



$('.j-carousell-1').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    asNavFor: '.j-carousell-nav-1,.j-carousell-img-1',
    prevArrow: '.j-carousell-1--prev',
    nextArrow: '.j-carousell-1--next',
    appendDots: '.j-carousell-1--dots'
});

$('.j-carousell-nav-1').slick({
    slidesToShow: 6,
    slidesToScroll: 1,
    asNavFor: '.j-carousell-1,.j-carousell-img-1',
    focusOnSelect: true,
    dots: false,
    arrows: false

});


$('.j-carousell-img-2').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: false,
    dots: false,
    asNavFor: '.j-carousell-nav-2'
});

$('.j-carousell-2').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    asNavFor: '.j-carousell-nav-2, .j-carousell-img-2',
    prevArrow: '.j-carousell-2--prev',
    nextArrow: '.j-carousell-2--next',
    appendDots: '.j-carousell-2--dots'
});

$('.j-carousell-nav-2').slick({
    slidesToShow: 6,
    slidesToScroll: 1,
    asNavFor: '.j-carousell-2,.j-carousell-img-2',
    focusOnSelect: true,
    dots: false,
    arrows: false

});



$('.sendform').each(function() {
    // Объявляем переменные (форма и кнопка отправки)
    var form = $(this),
        btn = form.find('.btn');

    // Добавляем каждому проверяемому полю, указание что поле пустое
    form.find('.req').addClass('empty_field');

    // Функция проверки полей формы
    function checkInput() {
        form.find('.req').each(function() {
            if ($(this).val() != '') {
                // Если поле не пустое удаляем класс-указание
                $(this).removeClass('empty_field');
            } else {
                // Если поле пустое добавляем класс-указание
                $(this).addClass('empty_field');
            }

        });

    }

    // Функция подсветки незаполненных полей
    function lightEmpty() {
        form.find('.empty_field').css({ 'border-color': '#d8512d' });
        // Через полсекунды удаляем подсветку
        setTimeout(function() {
            form.find('.empty_field').removeAttr('style');
        }, 1500);
    }

    // Проверка в режиме реального времени
    setInterval(function() {
        // Запускаем функцию проверки полей на заполненность
        checkInput();
        // Считаем к-во незаполненных полей
        var sizeEmpty = form.find('.empty_field').size();
        // Вешаем условие-тригер на кнопку отправки формы
        if (sizeEmpty > 0) {
            if (btn.hasClass('disabled')) {
                return false
            } else {
                btn.addClass('disabled')
            }
        } else {
            btn.removeClass('disabled')
        }
    }, 500);

    // btn submit
    btn.click(function(e) {
        if ($(this).hasClass('disabled')) {
            // highlight if empty
            lightEmpty();
            return false;
        } else {

             e.preventDefault();
    
    $.ajax({
      url: "mailsend.php",
      type: "POST",
      data: $('#osForm').serialize(),
      success: function(response) {
        
        //success sending process
          $("#success-modal").fadeIn("slow");
            setTimeout(function() { $("#success-modal").fadeOut("slow") }, 3000);
      },
      error: function(response) {
        //обработка ошибок при отправке
     }
    });

            // Все хорошо, все заполнено, отправляем форму
            // form.submit();
        }
    });
});



/*** change words ***/
jQuery(document).ready(function ($) {
  //set animation timing
  var animationDelay = 2500,

  //loading bar effect
  barAnimationDelay = 3800,
      barWaiting = barAnimationDelay - 3000,
      //3000 is the duration of the transition on the loading bar - set in the scss/css file
  //letters effect
  lettersDelay = 50,

  //type effect
  typeLettersDelay = 150,
      selectionDuration = 500,
      typeAnimationDelay = selectionDuration + 800,

  //clip effect 
  revealDuration = 600,
      revealAnimationDelay = 1500;

  initHeadline();

  function initHeadline() {
    //insert <i> element for each letter of a changing word
    singleLetters($('.cd-headline.letters').find('b'));
    //initialise headline animation
    animateHeadline($('.cd-headline'));
  }

  function singleLetters($words) {
    $words.each(function () {
      var word = $(this),
          letters = word.text().split(''),
          selected = word.hasClass('is-visible');
      for (i in letters) {
        if (word.parents('.rotate-2').length > 0) letters[i] = '<em>' + letters[i] + '</em>';
        letters[i] = selected ? '<i class="in">' + letters[i] + '</i>' : '<i>' + letters[i] + '</i>';
      }
      var newLetters = letters.join('');
      word.html(newLetters).css('opacity', 1);
    });
  }

  function animateHeadline($headlines) {
    var duration = animationDelay;
    $headlines.each(function () {
      var headline = $(this);

      if (headline.hasClass('loading-bar')) {
        duration = barAnimationDelay;
        setTimeout(function () {
          headline.find('.cd-words-wrapper').addClass('is-loading');
        }, barWaiting);
      } else if (headline.hasClass('clip')) {
        var spanWrapper = headline.find('.cd-words-wrapper'),
            newWidth = spanWrapper.width() + 10;
        spanWrapper.css('width', newWidth);
      } else if (!headline.hasClass('type')) {
        //assign to .cd-words-wrapper the width of its longest word
        var words = headline.find('.cd-words-wrapper b'),
            width = 0;
        words.each(function () {
          var wordWidth = $(this).width() + 15;
          if (wordWidth > width) width = wordWidth;
        });
        //headline.find('.cd-words-wrapper').css('width', width);
      };

      //trigger animation
      setTimeout(function () {
        hideWord(headline.find('.is-visible').eq(0));
      }, duration);
    });
  }

  function hideWord($word) {
    var nextWord = takeNext($word);

    if ($word.parents('.cd-headline').hasClass('type')) {
      var parentSpan = $word.parent('.cd-words-wrapper');
      parentSpan.addClass('selected').removeClass('waiting');
      setTimeout(function () {
        parentSpan.removeClass('selected');
        $word.removeClass('is-visible').addClass('is-hidden').children('i').removeClass('in').addClass('out');
      }, selectionDuration);
      setTimeout(function () {
        showWord(nextWord, typeLettersDelay);
      }, typeAnimationDelay);
    } else if ($word.parents('.cd-headline').hasClass('letters')) {
      var bool = $word.children('i').length >= nextWord.children('i').length ? true : false;
      hideLetter($word.find('i').eq(0), $word, bool, lettersDelay);
      showLetter(nextWord.find('i').eq(0), nextWord, bool, lettersDelay);
    } else if ($word.parents('.cd-headline').hasClass('clip')) {
      $word.parents('.cd-words-wrapper').animate({ width: '2px' }, revealDuration, function () {
        switchWord($word, nextWord);
        showWord(nextWord);
      });
    } else if ($word.parents('.cd-headline').hasClass('loading-bar')) {
      $word.parents('.cd-words-wrapper').removeClass('is-loading');
      switchWord($word, nextWord);
      setTimeout(function () {
        hideWord(nextWord);
      }, barAnimationDelay);
      setTimeout(function () {
        $word.parents('.cd-words-wrapper').addClass('is-loading');
      }, barWaiting);
    } else {
      switchWord($word, nextWord);
      setTimeout(function () {
        hideWord(nextWord);
      }, animationDelay);
    }
  }

  function showWord($word, $duration) {
    if ($word.parents('.cd-headline').hasClass('type')) {
      showLetter($word.find('i').eq(0), $word, false, $duration);
      $word.addClass('is-visible').removeClass('is-hidden');
    } else if ($word.parents('.cd-headline').hasClass('clip')) {
      $word.parents('.cd-words-wrapper').animate({ 'width': $word.width() + 10 }, revealDuration, function () {
        setTimeout(function () {
          hideWord($word);
        }, revealAnimationDelay);
      });
    }
  }

  function hideLetter($letter, $word, $bool, $duration) {
    $letter.removeClass('in').addClass('out');

    if (!$letter.is(':last-child')) {
      setTimeout(function () {
        hideLetter($letter.next(), $word, $bool, $duration);
      }, $duration);
    } else if ($bool) {
      setTimeout(function () {
        hideWord(takeNext($word));
      }, animationDelay);
    }

    if ($letter.is(':last-child') && $('html').hasClass('no-csstransitions')) {
      var nextWord = takeNext($word);
      switchWord($word, nextWord);
    }
  }

  function showLetter($letter, $word, $bool, $duration) {
    $letter.addClass('in').removeClass('out');

    if (!$letter.is(':last-child')) {
      setTimeout(function () {
        showLetter($letter.next(), $word, $bool, $duration);
      }, $duration);
    } else {
      if ($word.parents('.cd-headline').hasClass('type')) {
        setTimeout(function () {
          $word.parents('.cd-words-wrapper').addClass('waiting');
        }, 200);
      }
      if (!$bool) {
        setTimeout(function () {
          hideWord($word);
        }, animationDelay);
      }
    }
  }

  function takeNext($word) {
    return !$word.is(':last-child') ? $word.next() : $word.parent().children().eq(0);
  }

  function takePrev($word) {
    return !$word.is(':first-child') ? $word.prev() : $word.parent().children().last();
  }

  function switchWord($oldWord, $newWord) {
    $oldWord.removeClass('is-visible').addClass('is-hidden');
    $newWord.removeClass('is-hidden').addClass('is-visible');
  }
});